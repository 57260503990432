import axios from '../../utils/axios';

const actionTypes = {
    PERSONAL_INFO: 'PERSONAL_INFO',
    PERSONAL_INFO_SUCCESS: 'PERSONAL_INFO_SUCCESS',
    PERSONAL_INFO_ERROR: 'PERSONAL_INFO_ERROR',
    PERSONAL_INFO_POST: 'PERSONAL_INFORMATION_POST',
}

export const personalInfo = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.PERSONAL_INFO
        })
        return axios
            .get('/api/lk/personal_info')
            .then(res => {
                dispatch({
                    type: actionTypes.PERSONAL_INFO_SUCCESS,
                    payload: {
                        phone: res.data.phone,
                        phone2: res.data.phone2,
                        email: res.data.email,
                        name: res.data.name,
                        surname: res.data.surname,
                        middlename: res.data.middlename,
                        address: res.data.address,
                        TgToken: res.data.TgToken
                    }
                })
            })
            .catch(error => {
            dispatch({
                type: actionTypes.PERSONAL_INFO_ERROR,
                payload: {
                    message: error.payload.message
                }
            })
        })
    }
}

export const personalInfoPost = (params) => {
    return (dispatch) => {
        return axios
            .post('/api/lk/personal_info', {
                phone: params.phone,
                phone2: params.phone2,
                email: params.email
            })
            .then(res => {
                dispatch(personalInfo())
            })
    }
}