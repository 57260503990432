import axios from 'axios';

const actionTypes = {
    LOGOUT: 'LOGOUT',
    STATUS_MESSAGE: 'STATUS_MESSAGE'
}

export const checkAuth = (params) => {
    return (dispatch) => {
        return axios
            .post('/api/lk/auth', {
                login: params.login,
                password: params.password
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then( response => {
                return response;
            })
            .catch(error => {
                if (error.response.status === 401){
                    dispatch(
                        {
                            type: actionTypes.STATUS_MESSAGE,
                            payload: {
                                message: 'Не правильный логин или пароль',
                            }
                        }
                    )
                }

                throw new Error('Не правильный логин или пароль')

            })
    }

};

export const logOut =  () => {
    return (dispatch) => {
        return axios
            .get('/api/lk/logout')
            .then(dispatch(
                {
                    type: actionTypes.LOGOUT,
                }
            ))
            .catch(error => {

            })
    }
}
