import React, { useCallback, useState } from 'react';
import { useActions } from '../../../../hooks/useActions';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import { useSelector } from 'react-redux';
import Input from '../../../common/Input';

import Label from '../../../common/Label/Label';
import Button from '../../../common/Button/Button';

import style from './FeedBackButton.module.css';

const maskOptions = {
    mask: '+{7}(000)000-00-00'
};

export default function FeedBackButton () {

    const { phone } = useSelector(state => state.baseInfo.info);
    const [token, setToken] = useState('');
    const [newPhone, setNewPhone] = useState(phone);
    const [visible, setVisible] = useState(true);
    const handleChallengeHidden = useCallback(() => setVisible(false), []);

    const { sendInfo, hideWindow } = useActions();
    const [message, setMessage] = useState('');
    const [errorPhoneMessage, setErrorPhoneMessage] = useState(false);
    const [errorTextMessage, setErrorTextMessage] = useState(false);


    const validate = () => {
        const regex = new RegExp(/^[+7|7|8]9(\d){9}$/);
        return regex.test(newPhone)

    }

    const handleButtonClick =(event) => {
        event.preventDefault()
        if (!validate()){
            return setErrorPhoneMessage(true)
        }
        if(message.length < 4) {
            return setErrorTextMessage(true)
        }

        let sendPhone = newPhone;
        if (newPhone === phone) {
            sendPhone = phone
        }
        sendInfo({
            'message': {
                'message': message,
                'phone': sendPhone
            },
            'token': token,
            'path': 'lkFeedBack'
        })
        hideWindow()
    }

    const handleChangePhone = (inputValue) => {
        setNewPhone(inputValue);
    }

    return(
        <div className={style.root}>
            <form>
                <Label title={'Оставьте заявку и вам обязательно ответят'} >
                <textarea
                    className={style.textArea}
                    onChange={(e) => {setMessage(e.target.value)}}
                    placeholder={'Введите здесь текст сообщения'}
                >
                </textarea>
                    {errorTextMessage && (
                        <div className={style.error}>Введите текст сообщения</div>
                    )}
                </Label>
                <div className={style.phone}>
                    <Label title={'Телефон для связи'}>
                        <Input
                            onChange={handleChangePhone}
                            type={'tel'}
                            placeholder={phone}
                            name={'phone'}
                            maskOptions={maskOptions}
                        />
                    </Label>
                    {errorPhoneMessage && (
                        <div className={style.error}>Введен некоректный номер</div>
                    )}

                </div>
                <InvisibleSmartCaptcha
                    sitekey={process.env.REACT_APP_CAPTCHA_KEY || '123' }
                    onSuccess={setToken}
                    onChallengeHidden={handleChallengeHidden}
                    visible={visible}
                    shieldPosition={'center-right'}
                />
                <div className={style.buttonContainer}>
                    <Button
                        type={'submit'}
                        className={style.buttonWidth}
                        size='large'
                        onClick={handleButtonClick}>
                        Отправить
                    </Button>
                </div>
            </form>

        </div>
    );
}
