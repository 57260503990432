export interface ChannelsState {
    packages: FetchTVResponse;
    package: FetchPackageResponse | null;
    loading: boolean;
    error: null | string;
}

export interface FetchTVResponse {
    count: number;
    packets: ITv []
}
export interface FetchPackageResponse {
    count: number;
    news: IPackage []
}
export interface ITv {
    providerId?: number,
    name: string,
    price: string,
    price_type: string,
    info: string,
}
export interface IPackage {
    count: number,
    name: string,
    short: string,
    price: string,
    channels: IChannel [],
}
export interface IChannel {
    name: string,
    icon: string,
}

export enum ChannelsActionTypes {
    FETCH_TV = 'FETCH_TV',
    FETCH_TV_SUCCESS  = 'FETCH_TV_SUCCESS',
    FETCH_TV_ERROR = 'FETCH_TV_ERROR',
    FETCH_PACKAGE = 'FETCH_PACKAGE',
    FETCH_PACKAGE_SUCCESS  = 'FETCH_PACKAGE_SUCCESS',
    FETCH_PACKAGE_ERROR = 'FETCH_PACKAGE_ERROR',
}

interface FetchTVAction {
    type: ChannelsActionTypes.FETCH_TV
}

interface FetchTVSuccessAction {
    type: ChannelsActionTypes.FETCH_TV_SUCCESS
    payload: FetchTVResponse
}

interface FetchTVErrorAction {
    type: ChannelsActionTypes.FETCH_TV_ERROR
    payload: string
}
interface FetchPackageAction {
    type: ChannelsActionTypes.FETCH_PACKAGE
}

interface FetchPackageSuccessAction {
    type: ChannelsActionTypes.FETCH_PACKAGE_SUCCESS
    payload: FetchPackageResponse
}

interface FetchPackageErrorAction {
    type: ChannelsActionTypes.FETCH_PACKAGE_ERROR
    payload: string
}

export type ChannelsAction = FetchTVAction | FetchTVSuccessAction | FetchTVErrorAction
    | FetchPackageAction | FetchPackageSuccessAction | FetchPackageErrorAction;
