import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../../../hooks/useActions';

import style from './ListChannels.module.css'


export default function ListChannels () {

    const { channels, price, priceType, short } = useSelector(state => state.listChannels.infoChannels);

    if (!channels) {
        return
    }

    return (
        <div>
            <div className={style.short}>{short}</div>
            <div className={style.price}>{price} руб / {priceType === 'day'? 'сутки' : 'месяц'}</div>
            <div className={style.root}>
                {channels.map(({name, icon, id}) => {
                    return (
                        <div className={style.listContainer} key={id}>
                            <div className={style.listItem} style={{backgroundImage: `url(${icon})`}}></div>
                            <div className={style.name}>{name}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
