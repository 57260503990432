import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../hooks/useActions';

import { ReactComponent as TvIcon } from '../../../../static/images/Vector.svg';
import DropDown from '../../../common/DropDown/DropDown';
import ConfirmExtraPackets from './components/confimExtraPackets/ConfirmExtraPackets';
import ConfirmPackets from './components/confimExtraPackets/ConfirmExtraPackets';
import ListChannels from './components/ListСhannels/ListChannels';
import SwitchToggle from '../../../common/SwitchToggle';
import { isAdaptive } from '../../../../hoc/isAdaptive';
import { Tooltip } from 'react-tooltip';
import Button from '../../../common/Button';

import style from './MainChannel.module.css'




 function MainChannel ({ children, viewType, tvInfoSelectedId }) {

    const {
        showWindow, tvPackets, fetchTvProvider,
        setSelectedBasePacket, deleteSelectedBasePacket,
        setSelectedExtraPacket, deleteSelectedExtraPacket, ListTvInfo
    } = useActions();

    const { basePackets, extraPackets } = useSelector(state => state.allTvPackets.allTvInfo);
    const { providers } = useSelector(state => state.tvProviders.data);
    const selectedId = useSelector(state => state.allTvPackets.id);
    const selectedIdExtra = useSelector(state => state.allTvPackets.extraId);
    const [selectedIds, setSelectedIds] = useState({});
    const [provider, setProvider] = useState('default');
    const [changeStyleButtonForId, setChangeStyleButtonForId] = useState('');


    useEffect(() => {
        tvPackets('default')
        fetchTvProvider()
    }, [])

    useEffect(() => {
        setSelectedIds(selectedIdExtra);
    }, [selectedIdExtra])

     if (!basePackets || !extraPackets) {
         return
     }


    const handleToggleMain = (id) => {
        const content = <ConfirmPackets confirm={confirmBaseDialog} id={id} />;
        showWindow({
            content,
            header: true,
            title: "Подтвердите свой выбор",
        })
    }

    const confirmBaseDialog = (packetId) => {
        if (selectedId !== packetId) {
            setSelectedBasePacket(packetId)
        } else  {
            deleteSelectedBasePacket(packetId)
        }
    }

    const handleToggleExtra = (id) => {
        const content = <ConfirmExtraPackets confirm={confirmExtraDialog} id={id} />;
        showWindow({
            content,
            header: true,
            title: "Подтвердите свой выбор",
        })
    }

    const confirmExtraDialog = (id) => {
        const ids = {...selectedIds};
        const value = ids[id]
        ids[id] = !value
        if (ids[id]) {
            setSelectedExtraPacket({
                id
            }).then(response => {
                if (response.status === 200){
                    setSelectedIds(ids)
                }
            })
        } else {
            deleteSelectedExtraPacket({
                id
            }).then(response => {
                if (response.status === 200) {
                    setSelectedIds(ids)
                }
            })
        }

    }


    const handleClickPacketBase = async (name, id) => {

        await ListTvInfo({id, provider})
        const content = <ListChannels id={id} provider={provider}  />;
        showWindow({
            content,
            header: true,
            showCloseButton: true,
            title: name,
            scrollBlock: true
        })
    }


     const handleSwitchProvider =  (id) => {
         setChangeStyleButtonForId(id)
         tvPackets(id)
         setProvider(id)
         tvInfoSelectedId(id)
     }


    return (
        <div>
            <div className={style.title}>Выберете ТВ провайдера:</div>
            <div className={style.buttonContainer}>
                {providers.map(({id, name, enabled}) => {
                    let selected = false
                    if (changeStyleButtonForId === id ){
                        selected = true
                    } else if (! changeStyleButtonForId) {
                        selected = enabled
                    }
                    return (
                        <div key={id} className={style.button}>
                            <Button
                                selectedButton= {selected}
                                size={'large'}
                                onClick={() => handleSwitchProvider(id)}
                                theme={'primary'}
                            >
                                {name}
                            </Button>
                        </div>
                    )
                })}
            </div>
            <DropDown title='Основные пакеты' hideBottomLine={true} openDropDown={true}>

                {basePackets.map(({ id, name, price, info, price_type, allow_activate, required_message, ...rest } ) => {
                    return (
                        <div className={style.root} key={id}>
                                <div className={style.mainContainer}>
                                    {viewType === 'mobile' && (
                                        <>
                                            <div>
                                                <div className={style.name}>{name}</div>
                                                <div className={style.countChanel} onClick={()=> handleClickPacketBase(name,  rest['providerId'])}>
                                                    <TvIcon/>
                                                    <div className={style.count}>{info}</div>
                                                </div>
                                            </div>

                                            <div className={style.rootPriceContainer}>
                                                <div>
                                                    <div className={style.price}>{price}</div>
                                                    <div className={style.priceType}>руб / {price_type === 'day'? 'сут' : 'мес'}</div>
                                                </div>

                                                    <div data-tooltip-id={id}>
                                                        <SwitchToggle
                                                            value={selectedId === id}
                                                            id={id}
                                                            onClick={handleToggleMain}
                                                            enable={allow_activate}
                                                        />
                                                        {!allow_activate && (
                                                            <Tooltip id={id}>
                                                                <div className={style.requiredMessage}>{required_message}</div>
                                                            </Tooltip>
                                                        )}
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {viewType !== 'mobile' && (
                                        <>
                                            <div className={style.name}>{name}</div>
                                            <div className={style.countChanel} onClick={()=> handleClickPacketBase(name, rest['providerId'])}>
                                                <div className={style.lineTv}></div>
                                                <TvIcon/>
                                                <div className={style.count}>{info}</div>
                                                <div className={style.lineTv}></div>
                                            </div>
                                            <div className={style.rootPriceContainer}>
                                                <div className={style.price}>{price}</div>
                                                <div className={style.priceType}>руб / {price_type === 'day'? 'сут' : 'мес'}</div>
                                            </div>

                                            <div data-tooltip-id={id} className={style.switcher}>
                                                <SwitchToggle
                                                    value={selectedId === id}
                                                    id={id}
                                                    onClick={handleToggleMain}
                                                    enable={allow_activate}
                                                />
                                            </div>
                                            {!allow_activate && (
                                                <Tooltip id={id}>
                                                    <div className={style.requiredMessage}>{required_message}</div>
                                                </Tooltip>
                                            )}

                                        </>
                                    )}
                                </div>
                        </div>
                    )
                })}
            </DropDown>
            <DropDown title='Дополнительные пакеты'>
                {extraPackets.map(({ id, name, price, info, allow_activate, required_message, ...rest } ) => {
                    return (
                        <div className={style.root} key={id}>
                                <div className={style.mainContainer}>
                                    {viewType === 'mobile' && (
                                        <>
                                            <div>
                                                <div className={style.name}>{name}</div>
                                                <div className={style.countChanel} onClick={()=> handleClickPacketBase(name, rest['providerId'])}>
                                                    <TvIcon/>
                                                    <div className={style.count}>{info}</div>
                                                </div>
                                            </div>
                                            <div className={style.rootPriceContainer}>
                                                <div>
                                                    <div className={style.price}>{price}</div>
                                                    <div className={style.priceType}>
                                                        <div>руб / сут.</div>
                                                    </div>
                                                </div>

                                                    <div data-tooltip-id={id}>
                                                        <SwitchToggle
                                                            value={selectedIds[id]}
                                                            id={id}
                                                            onClick={handleToggleExtra}
                                                            enable={allow_activate}
                                                        />
                                                    </div>
                                                {!allow_activate && (
                                                    <Tooltip id={id} className={style.requiredMessage}>
                                                        <div className={style.requiredMessage}>{required_message}</div>
                                                    </Tooltip>
                                                )}

                                            </div>
                                        </>
                                    )}

                                    {viewType !== 'mobile' && (
                                        <>
                                            <div className={style.name}>{name}</div>
                                            <div className={style.countChanel} onClick={()=> handleClickPacketBase(name, rest['providerId'])}>
                                                <div className={style.lineTv}></div>
                                                <TvIcon/>
                                                <div className={style.count}>{info}</div>
                                                <div className={style.lineTv}></div>
                                            </div>
                                            <div className={style.priceContainer}>
                                                <div className={style.price}>{price}</div>
                                                <div className={style.priceType}>
                                                    <div>руб / сут.</div>
                                                </div>
                                            </div>

                                                <div data-tooltip-id={id} className={style.switcher}>
                                                    <SwitchToggle
                                                        value={selectedIds[id]}
                                                        id={id}
                                                        onClick={handleToggleExtra}
                                                        enable={allow_activate}
                                                    />
                                                </div>
                                            {!allow_activate && (
                                                <Tooltip id={id} className={style.requiredMessage}>
                                                    <div className={style.requiredMessage}>{required_message}</div>
                                                </Tooltip>
                                            )}
                                        </>
                                    )}
                                </div>
                        </div>
                    )
                })}
            </DropDown>
        </div>

    )
}

export default isAdaptive(MainChannel)
