import React from 'react';

import Card from '../../../../../common/Card';
import { ITv } from '../../../../../../types/channels';
import Button from '../../../../../common/Button';
import ConnectTvAndInternet from "../../../ConnectEthernet/ConnectTvAndInternet";
import { useActions } from "../../../../../../hooks/useActions";


import styles from './ChannelItem.module.css'
import ListChannels from '../../../../../ListTv/components/MainChannel/components/ListСhannels/ListChannels';

const priceTypes = {
    day: 'сут.',
    month: 'мес.',
}

const ChannelItem: React.FC<ITv> = (props ) => {

    const { showWindow } = useActions();
    // @ts-ignore
    const priceType = priceTypes[props.price_type];

    const handleClick = () => {
        const content = <ConnectTvAndInternet name={props.name} id={'tv'} />
        showWindow({
            content,
            title: 'Подключить телевидение',
            showCloseButton: true,
            header: true,
            scrollBlock: true
        })
    }


    const handleClickListChannels = () => {
        // @ts-ignore
        const content = <ListChannels id={props.providerId} provider={'default'}/>
        showWindow({
            content,
            title: props.name,
            showCloseButton: true,
            header: true,
            scrollBlock: true
        })
    }

    return (
        <Card>
            <div className={styles.root}>
                <div className={styles.left}>
                    <div>
                        <div className={styles.name}>
                            {props.name}
                        </div>
                        <div className={styles.channels} onClick={handleClickListChannels}>
                            Список каналов
                        </div>
                    </div>
                    {/*<div className={styles.count}>*/}
                    {/*    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*        <path d="M4.5 6.33333C4.5 5.8731 4.8731 5.5 5.33333 5.5H18.6667C19.1269 5.5 19.5 5.8731 19.5 6.33333V14.6667C19.5 15.1269 19.1269 15.5 18.6667 15.5H5.33333C4.8731 15.5 4.5 15.1269 4.5 14.6667V6.33333ZM8.93142 17.5583C9.2956 17.3034 9.72939 17.1667 10.1739 17.1667H13.8261C14.2706 17.1667 14.7044 17.3034 15.0686 17.5583L15.9807 18.1968C16.1142 18.2902 16.048 18.5 15.8851 18.5H8.11491C7.95196 18.5 7.88584 18.2902 8.01933 18.1968L8.93142 17.5583Z" stroke="#FF0000"/>*/}
                    {/*    </svg>*/}
                    {/*    {props.info}*/}
                    {/*</div>*/}
                </div>
                <div className={styles.right}>
                    <div className={styles.price}>
                        <div className={styles.amount}>
                            {props.price}
                        </div>
                        <div className={styles.currency}>
                            <div>
                                руб.
                            </div>
                            <div>
                                {priceType }
                            </div>
                        </div>
                    </div>
                    <Button theme={'primary'} size={'small'} onClick={handleClick}>Подключить</Button>
                </div>

            </div>
        </Card>
    );
}
export default ChannelItem
